import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

import InvestmentTile from "components/InvestmentTile"
import Office from "./components/Office"

const Content = ({ city, sub_title, investments, office, rmSubHeader }) => {
  const { t } = useLocale()
  return (
    <section className="investments-content">
      <div className="container-fluid">
        {!rmSubHeader && city && <h2>{t(city)}</h2>}
        {sub_title && <h3 className="investments-content__sub">{sub_title}</h3>}
        <div className="row">
          <div className={office ? "col-lg-8" : "col-lg-12"}>
            <div className="row">
              {investments
                ?.filter(item => item.city === city)
                ?.map(props => (
                  <div className={office ? "col-md-6" : "col-lg-4 col-md-6"}>
                    <InvestmentTile {...props} />
                  </div>
                ))}
            </div>
          </div>
          {office && (
            <div className="col-lg-4 col-md-6">
              <Office office={office} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Content
